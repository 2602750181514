export default {
    name: 'ArchiveExtract',

    computed: {
        categoryItem() {
            let arr = this.categorys[this.categoryIndex] || {};
            return arr;
        },
        categoryType() {
            let arr = this.categorys[this.categoryIndex] || {};
            return arr.category_key;
        },
        categorySubItems() {
            let items = this.categorys;
            let index = this.categoryIndex;
            return (items[index] && items[index].category_sub) || []
        },
        categoryThirdItems() {
            let index = this.categorySubIndex;
            return (this.categorySubItems[index] && this.categorySubItems[index].category_sub) || [];
        }
    },

    mounted() {
        this.handleGetCategoryList()
    },

    methods: {
        // 清空选择框
        clearSelect(e, i) {
            this.$refs[e].clearSingleSelect();
            if (i == '-1') {
               this.$nextTick(()=>{
                if (e == 'resetSubSelect') {//第一级选择全部
                    this.categorySubIndex = '-1';
                }
                this.categoryThirdIndex = '-1';
               })
            }
        },
        // 监听第三级分类回调
        changes(e) {
            if (!this.categoryThirdIndex || this.categoryThirdIndex.length == 0) return
            let st = this.categoryThirdIndex.indexOf('-1') > -1;
            if (st) this.categoryThirdIndex = ['-1']
            this.checkMultiple = !st;
        },
        // 获取项目类别列表
        handleGetCategoryList() {
            if (this.loading) return;
            this.loading = true
            this.$api.archives.categoryList({permission_type:0}).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.categorys = res.list.map(v => {
                    v.open = true;
                    v.add = false;
                    return v;
                })
                this.handleApplyLogList();
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 员工提档记录
        handleApplyLogList() {
            if (this.loading) return;
            this.loading = true
            this.$api.archives.applyLogList({
                page: this.page,
                page_number: 20
            }).then(res => {
                this.loading = false
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.items = res.list;
                this.obj = res.obj;
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 搜索
        handleQueryChange(keyword) {

            if (this.isAjax) return;
            this.isAjax = true;
            let options = {};
            if (this.categoryItem.category_key == 'partner') {
                options = {
                    search_type: "partner",
                    company_name: keyword,
                    source: 'pickup',
                    use_case:2,
                }
            }else{
                options = {
                    search_type: this.categoryIndex == '-1' ? '' : this.categoryItem.category_key,
                    second_category_id: this.categorySubIndex == '-1' ? 0 : (typeof this.categorySubIndex != 'number') ? '' : this.categoryItem.category_sub[this.categorySubIndex].category_id,
                    third_category_ids: this.categoryThirdIndex == '-1' ? [] : this.categoryThirdIndex,
                    archives_name: keyword,
                    name: keyword,
                    source: 'pickup',
                    use_case:2,
                };
            }
            this.$api.archives.archivesSearch(options).then(res => {
                this.isAjax = false
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.selectItems = res.list;
            }).catch(err => {
                this.isAjax = false
                console.log(err)
            })
        },
        change(e){
            this.date=e;
        },

        // 员工提档申请
        handleSubmit() {
            if (this.loading) return;

            if (this.archives_code == '' && this.material_type==2) return this.$Message.warning({ content: '请输入文件名(或姓名)' })
            if (this.file_name == '' && this.material_type==1) return this.$Message.warning({ content: '请输入文件名(或姓名)' })
            if (this.use == '') return this.$Message.warning({ content: '请输入文件用途' })
            if (this.date.length <= 0) return this.$Message.warning({ content: '请选择使用时间' })

            this.loading = true
            this.$Message.loading({ content: '请求中...', duration: 0 })
            this.$api.archives.archivesApply({
                archives_code: this.archives_code,
                file_name:this.file_name,
                use: this.use,
                material_type: this.material_type,
                begin_date: this.date[0],
                end_date: this.date[1]
            }).then(res => {
                this.loading = false
                this.$Message.destroy()
                if (res.status_code != 0) return this.$Message.warning({ content: res.status_msg })
                this.$Message.success({ content: '档案提取成功' })
                this.handleApplyLogList();
                this.archives_code = '';
                this.file_name = '';
                this.use = '';
                this.material_type = 2;
                this.date = [];
                eventBus.$emit('on-undohandls')
            }).catch(err => {
                this.loading = false
                this.$Message.destroy()
                this.$Message.error({ content: '档案提取失败' })
                console.log(err)
            })
        }
    },

    data() {
        return {
            loading: false,
            isAjax: false,
            selectItems: [],

            page: 0,

            categoryIndex: '-1',
            categorySubIndex: '-1',
            categoryThirdIndex: '-1',
            categorys: [],
            checkMultiple: true,
            items: [],
            obj: {},
            date: [],
            use: '',
            archives_code: '',
            file_name:'',
            material_type: 2, // 需要材料类型：1-纸质材料，2-电子材料
            columns: [
                {
                    title: '提取时间',
                    slot: 'date',
                    width: 200
                },
                {
                    title: '档案内容',
                    slot: 'content',
                    minWidth: 240
                },
                {
                    title: '状态',
                    slot: 'status',
                    width: 120
                }
            ]
        }
    }
}